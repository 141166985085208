import React from 'react';
import '../App.css';

const LoadingScreen = () => {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center">
        <img 
        src="/image/logo.jpeg" 
        alt="Logo Empresa" 
        className="logo" 
        onError={() => console.error('Error: Logo no encontrado.')}   
        />
      </div>
    );
  };

export default LoadingScreen;