// src/components/SidebarCart.js
import React from 'react';
import { Offcanvas, ListGroup, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SidebarCart = ({ show, cartItems, onHide, onRemoveItem }) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Carrito</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {cartItems.length > 0 ? (
          <>
            <ListGroup>
              {cartItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col>{item.name} - ${item.price}</Col>
                    <Col xs="auto">
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onRemoveItem(index)}
                      >
                        Eliminar
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h4 className="mt-3">
              Total: ${cartItems.reduce((total, item) => total + item.price, 0)}
            </h4>
            <Link to="/carrito">
              <Button variant="primary" className="mt-3">
                Ir al Carrito
              </Button>
            </Link>
          </>
        ) : (
          <p>El carrito está vacío.</p>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SidebarCart;