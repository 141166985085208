// src/components/Carrito.js
import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Button, Row, Col } from 'react-bootstrap';
import NavigationBar from './Navbar';


const Carrito = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(savedCart);
  }, []);

  const calculateTotal = () =>
    cartItems.reduce((total, item) => total + item.price, 0);

  const handleSendWhatsApp = () => {
    const message = encodeURIComponent(
      `🛒 *Me Gustaria Cotizar*:\n` +
        cartItems.map((item, i) => `${i + 1}. ${item.name} - $${item.price}`).join('\n') +
        `\n\n💵 *Total*: $${calculateTotal()}`
    );

    const whatsappURL = `https://wa.me/56920748760?text=${message}`;
    window.open(whatsappURL, '_blank');
  };

  const handleRemoveItem = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  return (
    <Container className="mt-5">
    <NavigationBar cartCount={cartItems.length} /> {/* Barra de navegación */}
      <h1>Carrito</h1>
      {cartItems.length > 0 ? (
        <>
          <ListGroup>
            {cartItems.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row>
                  <Col>{item.name} - ${item.price}</Col>
                  <Col xs="auto">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveItem(index)}
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <h4 className="mt-3">Total: ${calculateTotal()}</h4>
          <Button
            variant="success"
            className="mt-3"
            onClick={handleSendWhatsApp}
          >
            Enviar por WhatsApp
          </Button>
        </>
      ) : (
        <p>El carrito está vacío.</p>
      )}
    </Container>
  );
};

export default Carrito;