// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Carrito from './components/Carrito';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/carrito" element={<Carrito />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);