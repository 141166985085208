// src/App.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductList from './components/ProductList';
import SidebarCart from './components/SidebarCart';
import LoadingScreen from './components/LoadingScreen';
import NavigationBar from './components/Navbar';

const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCart = async () => {
      try {
        const response = await fetch('/data/cart.json');
        if (!response.ok) {
          throw new Error('Error al cargar el carrito');
        }
        const data = await response.json();
        setCartItems(data);
      } catch (error) {
        console.error('Error al cargar el carrito:', error);
      } finally {
        // Simula una espera de 5 segundos antes de quitar la pantalla de carga
        setTimeout(() => setLoading(false), 5000);
      }
    };

    loadCart();
  }, []);


  const handleAddToCart = (product) => {
    const updatedCart = [...cartItems, product];
    setCartItems(updatedCart);
    setShowSidebar(true);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemoveFromCart = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  if (loading) return <LoadingScreen />;

  return (
    <div>
      <NavigationBar cartCount={cartItems.length} /> {/* Barra de navegación */}      
      <ProductList onAddToCart={handleAddToCart} />
      <SidebarCart
        show={showSidebar}
        cartItems={cartItems}
        onHide={() => setShowSidebar(false)}
        onRemoveItem={handleRemoveFromCart}
      />
    </div>
  );
};

export default App;