// src/components/ProductList.js
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import '../App.css'; // Importamos los estilos CSS

const ProductList = ({ onAddToCart }) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetch('/data/products.json')
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error al cargar los productos:', error));
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Guardamos la imagen seleccionada
    setShowModal(true); // Mostramos el modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cerramos el modal
    setSelectedImage(null); // Limpiamos la imagen seleccionada
  };

  return (
    <Container className="mt-5">
      <Row>
        {products.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={4} className="mb-4">
            <Card className="h-100">
              <div
                className="product-image-container"
                onClick={() => handleImageClick(product.image)}
              >
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
              </div>
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text>Precio: ${product.price}</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => onAddToCart(product)}
                >
                  Agregar al carrito
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal para mostrar la imagen ampliada */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="p-0">
          <div className="zoomed-image-container">
            <img src={selectedImage} alt="Zoomed" className="zoomed-image" />
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProductList;